/* body {
    font-size: 0.875rem;
    background-color: #eef0f2;
  } */
  /* @import url('https://cdn.jsdelivr.net/gh/StudioKonKon/bootstrap-konkon@1.3.0-beta/dist/css/studio-konkon.min.css'); */




  .comment-img {
    width: 3rem;
    height: 3rem;
  }
  .comment-replies .comment-img {
    width: 1.75rem;
    height: 1.75rem;
  }
  .comment-replies {
    background-color:'#f8f9fa'
  }

