.card {
  padding: 30px;
  width: 500px;
  border: solid 1px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.card:hover {
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.08);
}

.card-title {
  text-align: center;
  margin-top: 10px;
}

.card-text {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.card-text .fa {
  font-size: 26px;
}

.avatar {
  /* width: 140px;
    height: 140px; */
  margin: auto;
}

/* .avatar img {
    border: solid 6px transparent;
    border-radius: 50%;
} */

.btn {
  border-radius: 25px;
}

@media (max-width: 400px) {
  .card {
    padding: 5px;
    width: 280px;
  }
}

@media (max-width: 500px) {
  .card {
    padding: 5px;
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1040px;
  }
}
