.addOrCreateChatUser:hover {
  color: #9a76f5;
  font-size: 2em;
  transition: color 0.3s, font-size 0.4s;
}

.send-message-icon:hover {
  color: #9a76f5;
}

.chat_users_header {
  width: 100% !important; 
  background-color: #9a76f5;
  height: 80px;
  padding-top: 12px;
}

.chat_users_header_group {
  display: flex;
  flex-direction: row;
  margin-left: 45%;
  cursor: pointer;
}

/* *********************************** */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9a76f5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9a76f5;
}
/* *********************************** */
/* ------me------ */

.app {
  background-color: #ddd;
  width: '100%';
  /* height: 450px; */
  height: 630px;
  box-shadow: 0 2px 10px rgba(211, 211, 211, 0.25), 0 10px 10px rgba(211, 211, 211, 0.25);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.app .contact-list {
  background-color: white;
  width: 300px;
  /* overflow: auto; */
}

.app .contact-list .title {
  padding: 12px;
  margin: 0;
  padding-bottom: 0;
  font-size: 150%;
}

.app .contact-list ul {
  list-style: none;
  margin: 0;
  padding: 12px;
}

.app .contact-list ul li {
  margin: 8px 0;
  padding: 8px 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.app .contact-list ul li {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
}

.app .contact-list ul li.active,
.app .contact-list ul li:hover {
  /* background-color: #9a76f5; */
  background-color: #d15eff1d;
  /* color: #ffffff; */
  border-radius: 6px;
}

.app .messages {
  margin: 8px;
  background-color: white;
  border-radius: 6px;
  /* overflow: hidden; */
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.app .messages .messages-history {
  overflow: auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
}

.app .messages .messages-history .message {
  width: 100%;
  padding: 12px;
  padding-top: 0;
}

.app .messages .messages-history .message:last-child {
  padding-top: 12px;
}

.app .messages .messages-history .message.me {
  text-align: right;
}

.app .messages .messages-history .message.me .message-body {
  color: white;
  background-color: #9a76f5;
  word-wrap: break-word;
  border-radius: 18px 18px 0px 18px;
}

.app .messages .messages-history .message .message-body {
  background-color: #ddd;
  color: #333;
  font-weight: 400;
  display: inline-block;
  padding: 6px 15px;
  margin-right: 10px;
  margin-bottom: 2px;
  border-radius: 18px 18px 18px 0px;
  max-width: 420px;
  min-width: 75px;
  line-height: 20px;
  /* text-align: initial; */
  text-align: justify;
  text-justify: inter-word;
}

.app .messages .messages-history .message.me .message-body-file {
  color: white;
  background-color: #9a76f5;
  word-wrap: break-word;
  border-radius: 18px 18px 0px 18px;
  margin-top: 10px;
  margin-bottom: 2px;
}

.app .messages .messages-history .message .message-body-file {
  background-color: #ddd;
  color: #333;
  font-weight: 400;
  display: inline-block;
  padding: 6px 15px;
  margin-right: 10px;
  margin-bottom: 2px;
  border-radius: 18px 18px 18px 0px;
  max-width: 420px;
  min-width: 75px;
  line-height: 20px;
  margin-top: 10px;
  /* text-align: initial; */
  text-align: justify;
  text-justify: inter-word;
}

.app .messages .messages-inputs {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.app .messages .messages-inputs input[type='text'] {
  background-color: transparent;
  border: none;
  padding: 12px;
  resize: vertical;
  flex: 1 1 auto;
}

.app .messages .messages-inputs button {
  padding: 0 12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.app .messages .messages-inputs button:hover i {
  color: #00568f;
}

.app .messages .messages-inputs button i {
  vertical-align: middle;
  transition: all 0.2s ease-out;
}

.loadingImage {
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assest/loading.gif');
}
