.firstIndex{
    grid-column: span 2;
    grid-row: span 2;
    @media only screen and (max-width: 1024px) {
        grid-column: span 4;
        grid-row: span 2;
    }
    @media only screen and (max-width: 768px) {
        grid-column: span 8;
        grid-row: span 1;
    }
    @media only screen and (max-width: 500px) {
        grid-column: span 8;
        grid-row: span 1;
    }
}

.allIndex {
    grid-column: span 1;
    grid-row: span 1;
    @media only screen and (max-width: 1024px) {
        grid-column: span 2;
    }
    @media only screen and (max-width: 768px) {
        grid-column: span 4;
    }
    @media only screen and (max-width: 500px) {
        grid-column: span 8;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr); 
    gap: 10px;
}
